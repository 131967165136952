@font-face {
  font-family: "Hind";
   src: url("./fonts/Hind-Regular.ttf");
   font-weight: normal;
 }
 @font-face {
  font-family: "Hind";
  src: url("./fonts/Hind-Bold.ttf");
  font-weight: bold;
 }

html{
  scroll-behavior: smooth;  
}
 
 *{
  -ms-overflow-style: none;
  font-family: Hind;
}
::-webkit-scrollbar {
  display: none;
}

.App {
  text-align: center;
  background-color: #111111;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #111111;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.blog-title-emoji {
  animation-delay: 0.75s;
  animation-name: wave-animation;
  animation-fill-mode: both;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  transform-origin: 70% 65%;
  display: inline-block;
}

.name{
  animation-delay: 2.5s;
  animation-name: text-anim-up;
  animation-duration: 1s;
  position: absolute;
  opacity: 0%;
  animation-fill-mode: both;
  font-family: Hind;
  text-align: left;
  width: 75%;
  font-size: 7vh;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(14deg);
  }
  100% {
    transform: rotate(-8deg);
  }
  
}

.IntroText{
  animation-delay: 0.5s, 1.5s;
  color: #e6e6e6;
  animation-name: text-anim-up, moveToTitle;
  animation-fill-mode: both;
  animation-duration: 0.5s, 2s;
  animation-iteration-count: 1;
  position: absolute;
  font-family: Hind;
  font-size: 5vh;
}

@keyframes text-anim-up {
  0% {
    opacity: 0%;
  }
  100%{
    transform: translateY(-5px);
    opacity: 100%;
  }
  
}

@keyframes moveToTitle{
  0%{
    transform: translateY(0);
  }
  100%{
    transform: translateY(-20vh);
  }
}

.landing-button{
  animation-delay: 3s;
  animation-name: text-anim-up;
  animation-duration: 1s;
  animation-fill-mode: both;
  background-color: #111111;
  border: 1px solid white;
  border-radius: 1vh;
  padding: 2vh;
  margin: 3vh;
  color:white;
  opacity: 0%;
}

.landing-button:hover{
  background-color: #e6e6e6;
  color:#111111;
}

.contactButton:hover{
  background-color: #e6e6e6;
  color:#111111;
}


@keyframes visible{
  0%{
    opacity: 0%;
  }
  100%{
    opacity: 100%;
  }
}

.projects {
  background-color: #111111;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.contactMe {
  background-color: #111111;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.contactButton{
  background-color: #111111;
  border: 1px solid white;
  border-radius: 1vh;
  padding: 2vh;
  margin: 1vh;
  color:white;
}

h1{
margin-bottom: 0%;
}

.flexbox{
  display: flex;
  align-items: center;
}

