.bg{
width:75vw;
height:75vh;
background-position: 50% 20%;
background-size: 125%;
display: table-cell;
vertical-align: bottom;
text-align: left;
padding-left:2.5%;
margin-bottom: 10%;
padding-bottom: 1.2%;
background-repeat: no-repeat;
text-wrap: wrap;
line-height: 50%;
color: #b3b3b3;
/* filter: drop-shadow(0 0 0.25rem  rgb(36, 131, 255)); */
}

.bg:hover{
    animation-name: bg-up;
    animation-duration: 1s;
    animation-fill-mode: both;
    
}

@keyframes bg-up {
    0% {
    }
    100%{
      color:white;
    }
}

p{
    line-height: 150%;
}

h1{
    line-height: 75%;
}

.project:hover{
    animation-name: project-up;
    animation-duration: 1s;
    animation-fill-mode: both;
    
}

@keyframes project-up {
    0% {
    }
    100%{
      transform: translateY(-1.5%);
    }
}

.project{
    animation-name: project-down;
    animation-duration: 1s;
    animation-fill-mode: both;
}

@keyframes project-down {
    from {
        transform: translateY(-1.5%);
    }
    to{
        transform: translateY(0%);
    }
}






